import {createReducer, on} from '@ngrx/store';
import * as CProfileActions from '../actions/cProfile.actions'
import {CProfileModel} from "../models/cProfile.model";
import {EmailModel} from "../models/email.model";

export const PropositionFeatureKey = 'cProfile';

export interface State {
  cProfile: CProfileModel | null,
  emails: EmailModel[]
  loading: boolean,
  error: any

}

export const initialState: State = {
  cProfile: null,
  emails: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(CProfileActions.loadCProfile, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.loadCProfileSuccess, (state, {data}) => ({
    ...state,
    cProfile: data,
    loading: false,
    error: null
  })),
  on(CProfileActions.loadCProfileFailure, (state, {error}) => ({...state, loading: false, error})),
  on(CProfileActions.getEmails, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.getEmailsSuccess, (state, {data}) => ({
    ...state,
    emails: data,
    loading: false,
    error: null
  })),
  on(CProfileActions.getEmailsFailure, (state, {error}) => ({...state, loading: false, error})),
  on(CProfileActions.sendEmail, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.sendEmailSuccess, (state, {data}) => ({
    ...state,
    loading: false,
    error: null
  })),
  on(CProfileActions.getEmailsFailure, (state, {error}) => ({...state, loading: false, error})),
  on(CProfileActions.importLinkedin, (state) => ({...state, loading: true, error: null})),
  on(CProfileActions.importLinkedinSuccess, (state) => ({
    ...state,
    loading: false,
    error: null
  })),
  on(CProfileActions.importLinkedinFailure, (state, {error}) => ({...state, loading: false, error})),

);


