import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as JobOfferActions from '../actions/jobOffer.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {JobOfferService} from "../apis/jobOffer.service";

@Injectable()
export class JobOfferEffects {
  constructor(private actions$: Actions, private jobOfferService: JobOfferService, private store: Store,
              private toast: ToastrService) {
  }

  get$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.loadJobOffer),
        map((action: any) => action),
        mergeMap(({id, status}) => {
          return this.jobOfferService.get(id).pipe(
            map(data => JobOfferActions.loadJobOfferSuccess({data})),
            catchError(error => of(JobOfferActions.loadJobOfferFailure({error})))
          );
        })
      )
    }
  );

  updateStatus$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.updateStatus),
        map((action: any) => action),
        mergeMap(({id, status}) => {
          return this.jobOfferService.updateStatus(id, status).pipe(
            map(data => JobOfferActions.updateStatusSuccess({data})),
            catchError(error => of(JobOfferActions.updateStatusFailure({error})))
          );
        })
      )
    }
  );

  updateInternalStatus$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.updateInternalStatusSuccess),
        map((action: any) => action),
        mergeMap(({id, status}) => {
          return this.jobOfferService.updateInternalStatus(id, status).pipe(
            map(data => JobOfferActions.updateInternalStatusSuccess({data})),
            catchError(error => of(JobOfferActions.updateInternalStatusFailure({error})))
          );
        })
      )
    }
  );

  getMatchMaking$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.getMatchMaking),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.jobOfferService.getMatchMaking(id).pipe(
            map(data => JobOfferActions.getMatchMakingSuccess({data})),
            catchError(error => of(JobOfferActions.getMatchMakingFailure({error})))
          );
        })
      )
    }
  );

  sourcing$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(JobOfferActions.getSourcing),
        map((action: any) => action),
        mergeMap(({id, status}) => {
          return this.jobOfferService.getSourcing(id).pipe(
            map(data => JobOfferActions.getSourcingSuccess({data})),
            catchError(error => of(JobOfferActions.getSourcingFailure({error})))
          );
        })
      )
    }
  );
}
