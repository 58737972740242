import {createAction, props} from '@ngrx/store';
import {SubscriberModel} from "../models/subscriber.model";

export const loadCProfile = createAction(
  '[CProfile] Load CProfile',
  props<{ id: string }>()
);

export const loadCProfileSuccess = createAction(
  '[CProfile] Load CProfile Success',
  props<{ data: any }>()
);

export const loadCProfileFailure = createAction(
  '[CProfile] Load CProfile Failure',
  props<{ error: any }>()
);

export const getEmails = createAction(
  '[CProfile] Get Emails',
  props<{ id: string }>()
);

export const getEmailsSuccess = createAction(
  '[CProfile] Get Emails Success',
  props<{ data: any }>()
);

export const getEmailsFailure = createAction(
  '[CProfile] Get Emails Failure',
  props<{ error: any }>()
);

export const sendEmail = createAction(
  '[CProfile] Send Email',
  props<{ id: string, emailType: string }>()
);

export const sendEmailSuccess = createAction(
  '[CProfile] Send Email Success',
  props<{ data: any }>()
);

export const sendEmailFailure = createAction(
  '[CProfile] Send Email Failure',
  props<{ error: any }>()
);

export const importLinkedin = createAction(
  '[CProfile] Import Linkedin',
  props<{
    id: string, url: string, picture: boolean, experiences: boolean, educations: boolean,
    certifications: boolean, localisation: boolean, recommendations: boolean, skills: boolean, english: boolean,
    languages: boolean
  }>()
);

export const importLinkedinSuccess = createAction(
  '[CProfile] Import Linkedin Success',
  props<{ data: SubscriberModel }>()
);

export const importLinkedinFailure = createAction(
  '[CProfile] Import Linkedin Failure',
  props<{ error: any }>()
);
