export const environment = {
  production: true,
  NAME: 'preprod',

  API_URL: 'https://preprod-api.fiftytalents.com/admin',
  DATA_URL: 'https://preprod-api.fiftytalents.com/data/',
  APP_URL: 'https://preprod-app.fiftytalents.com/',
  API_OAUTH_URL: 'https://preprod-api.fiftytalents.com/oauth/token',
  OAUTH_CLIENT: '27',
  OAUTH_SECRET: 'nwykDVsyyXq8GzHfB4JwduRUFCxcfM9KZJXowrQs',
};
