import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {JobOfferModel} from "../models/jobOffer.model";
import {CProfileModel} from "../models/cProfile.model";


const API_URL = environment.API_URL;
const PATH = '/jobOffer/'

@Injectable({
  providedIn: 'root'
})
export class JobOfferService {

  constructor(private http: HttpClient) {
  }

  get(id: string) {
    return this.http.get<JobOfferModel>(API_URL + PATH + id)
  }

  updateStatus(id: string, status: string) {
    return this.http.patch<JobOfferModel>(API_URL + PATH + id + '/status', {status: status})
  }

  updateInternalStatus(id: string, body: any) {
    return this.http.patch<JobOfferModel>(API_URL + PATH + id + '/internalStatus', body)
  }

  getMatchMaking(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/matchMaking')
  }

  getSourcing(id: string) {
    return this.http.get<CProfileModel[]>(API_URL + PATH + id + '/sourcing')
  }
}
