import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CProfileActions from '../actions/cProfile.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {CProfileService} from "../apis/cProfile.service";

@Injectable()
export class CProfileEffects {
  constructor(private actions$: Actions, private cProfileService: CProfileService, private store: Store) {
  }

  loadCProfile$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.loadCProfile),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.loadCProfile(id).pipe(
            map(data => CProfileActions.loadCProfileSuccess({data})),
            catchError(error => of(CProfileActions.loadCProfileFailure({error})))
          );
        })
      )
    }
  );

  emails$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.getEmails),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.cProfileService.getEmails(id).pipe(
            map(data => CProfileActions.getEmailsSuccess({data})),
            catchError(error => of(CProfileActions.getEmailsFailure({error})))
          );
        })
      )
    }
  );

  sendEmail$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.sendEmail),
        map((action: any) => action),
        mergeMap(({id, emailType}) => {
          return this.cProfileService.sendEmail(id, emailType).pipe(
            map(data => CProfileActions.sendEmailSuccess({data})),
            catchError(error => of(CProfileActions.sendEmailFailure({error})))
          );
        })
      )
    }
  );

  importLinkedin$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CProfileActions.importLinkedin),
        map((action: any) => action),
        mergeMap(({id, url, picture, experiences, educations,
                    certifications, localisation, recommendations, skills, english,
                    languages}) => {
          return this.cProfileService.importLinkedin(id, url, picture, experiences, educations,
            certifications, localisation, recommendations, skills, english,
            languages).pipe(
            map(data => CProfileActions.importLinkedinSuccess({data})),
            catchError(error => of(CProfileActions.importLinkedinFailure({error})))
          );
        })
      )
    }
  );
}
